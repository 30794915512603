import { Component } from 'solid-js'
import { A } from '@solidjs/router'
import { ButtonProps } from '~/components/row-button/row-button.interfaces'
import styles from '~/components/row-button/row-button.module.scss'

const RowButton: Component<ButtonProps> = ({ label, onClick, href, className, ...props }) => {
  const Icon = props.icon
  const cssClasses = [styles.button, className].join(' ')

  if(onClick){
    return (
      <button
        class={cssClasses}
        onClick={onClick}
        title={label}
      >
        <Icon class={styles.icon} />
      </button>
    )
  }else if(href){
    return (
      <A
        class={cssClasses}
        href={href}
        title={label}
      >
        <Icon class={styles.icon} />
      </A>
    )
  }else{
    return null
  }
}

export default RowButton